import { defineStore } from "pinia";
import { ProductPage, ProductList, MainStore, MenuItem } from "~/types/common";

export const useMainStore = defineStore("mainStore", {
  state: (): MainStore => ({
    menuItems: null,
    headerVisibilityState: "show",
    cartVisible: false,
    isMobile: null,
    breakpoint: null,
  }),
  getters: {
    menuLevelsNumber(): number {
      if (this.thirdLevelItems !== null) {
        return 3;
      } else if (this.secondLevelItems !== null) {
        return 2;
      } else {
        return 1;
      }
    },
    firstLevelItems(state: MainStore): Array<MenuItem> | null {
      if (state.menuItems === null) {
        return null;
      }

      return state.menuItems;
    },
    firstLevelSelected(): MenuItem | null | undefined {
      if (this.firstLevelItems === null) {
        return null;
      }

      return this.firstLevelItems.find(
        (i) => "selected" in i && i.selected === true,
      );
    },
    secondLevelItems(): Array<MenuItem> | null {
      return this.firstLevelSelected && "items" in this.firstLevelSelected
        ? this.firstLevelSelected.items
        : null;
    },
    secondLevelSelected(): MenuItem | null | undefined {
      if (this.firstLevelItems === null || this.secondLevelItems === null) {
        return null;
      }

      return this.secondLevelItems.find(
        (i) => "selected" in i && i.selected === true,
      );
    },
    thirdLevelItems(): Array<MenuItem> | null {
      return this.secondLevelSelected && "items" in this.secondLevelSelected
        ? this.secondLevelSelected.items
        : null;
    },
  },
  actions: {
    deselectMenu(items: Array<MenuItem> | null = null) {
      if (items === null) {
        items = this.menuItems;
      }

      if (items === null) return; // to handle when we couldn't fetch the menu from API and it is in fact null

      for (const item of items) {
        if ("selected" in item) {
          delete item.selected;
        }

        if ("focused" in item) {
          delete item.focused;
        }

        if ("pressed" in item) {
          delete item.pressed;
        }

        if ("items" in item) {
          this.deselectMenu(item.items);
        }
      }
    },
  },
});

export const useProductPageStore = defineStore("productPage", {
  state: (): ProductPage => ({
    color: null,
    size: null,
    masterProduct: null,
    selectedVariant: null,
    selectedVariantSku: null,
    images: [],
    mainImage: 0,
    mainImageHeight: 0,
    manufacturer: null,
  }),
});

export const useProductListStore = defineStore("useProductListStore", {
  state: (): ProductList => ({
    paletteFilters: new Map(),
  }),
});
